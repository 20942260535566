import { object, string } from 'yup'

import messages from '@lib/utils/validation-messages'

const issueReportSchema = () =>
  object().shape({
    email: string().required(messages.required),
    message: string().required(messages.required),
    type: object().shape({ value: string().required(messages.required) })
  })

export default issueReportSchema
