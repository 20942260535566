import styled from 'styled-components'

const Link = styled.a`
  color: ${props => props.theme.colors.primary.default};
  font-family: inherit;
  font-size: inherit;
  opacity: 0.9;
  transition: opacity 200ms ease;

  :hover {
    opacity: 1;
  }
`

export default Link
