import styled from 'styled-components'

const Card = styled.section`
  position: relative;
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radii.large};
  margin: ${({ margin }) => margin || '1em 0'};
  padding: ${({ padding }) => padding || '2.5em'};
  box-shadow: ${({ theme }) => theme.shadows.itemContainer};
  flex: 1;
  width: 100%;
`

export default Card
