import { PageProgressMethods } from '@fullfacing/ff-web-next-utils'
import { Formik, Form } from 'formik'
import {
  Modal,
  Select,
  Input,
  Text,
  Button,
  Flex,
  FileInput
} from '@fullfacing/schoolbus'
import toast from 'react-hot-toast'

import { image, pdf } from '@lib/utils/upload-type-props'
import {
  REPORT_TEMPLATE_URI,
  REPORT_ISSUE_RECIPIENT
} from '@lib/config/constants'
import userContainer from '@lib/containers/user'
import api from '@api'

import issueReportSchema from './schema'
import copy from './copy'

const TYPE_OPTIONS = [
  { value: 'other', label: 'Other' },
  { value: 'view', label: 'Can’t view item' },
  { value: 'edit', label: 'Can’t edit item' },
  { value: 'add', label: 'Can’t save item' },
  { value: 'delete', label: 'Can’t delete item' }
]

const INITIAL_VALUES = {
  type: TYPE_OPTIONS[0],
  message: '',
  email: '',
  file: null
}

const FORM_DATA_CONFIG = {
  headers: {
    'content-type': 'multipart/form-data'
  }
}

const createRequestBody = ({ type, message, email, file, eventId }) => {
  const title = `Issue report from ${email} - ${type?.label}`
  const body = `${eventId ? `Event Id: ${eventId}<br>` : ''}${message}`

  if (!file) return { body, title, to: REPORT_ISSUE_RECIPIENT, from: email }

  const formData = new FormData()
  formData.append('from', email)
  formData.append('to', REPORT_ISSUE_RECIPIENT)
  formData.append('title', title)
  formData.append('file', file)
  formData.append('body', body)
  return formData
}

const ReportAnIssueModal = ({ onClose, details, ...otherProps }) => {
  const { eventId, errorType } = details || {}
  const handleSubmit = async values => {
    try {
      await api.support.report(
        createRequestBody({ ...values, eventId }),
        values.file ? FORM_DATA_CONFIG : {}
      )
      toast('Report has been sent')
      onClose()
    } catch (error) {
      console.error(error)
      toast.error('We were unable to send your report. Please try again later.')
    } finally {
      PageProgressMethods.done()
    }
  }

  return (
    <Modal
      title='Report an issue'
      width='40em'
      onClose={onClose}
      {...otherProps}
    >
      <Formik
        initialValues={{
          ...INITIAL_VALUES,
          type:
            TYPE_OPTIONS.find(({ value }) => value === errorType) ||
            INITIAL_VALUES.type,
          email: userContainer.state?.email || INITIAL_VALUES.email
        }}
        validationSchema={issueReportSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Select
              name='type'
              label='Issue type'
              width='100%'
              options={TYPE_OPTIONS}
              mt='0.5em'
            />

            <Input
              name='message'
              label='Your message'
              type='textarea'
              width='100%'
            />

            <Input name='email' label='Your email address' width='100%' />

            <Text fontSize='small' fontWeight='semi' color='grayscale.default'>
              {copy.preLink}
              <Text
                as='a'
                href={REPORT_TEMPLATE_URI}
                target='_blank'
                color='primary.default'
                fontWeight='bold'
                ml='0.5em'
              >
                {copy.link}
              </Text>
              {copy.postLink}
            </Text>

            <FileInput
              name='file'
              label='Upload image file (optional)'
              accept={`${image.accept},${pdf.accept}`}
              width='100%'
              mt='1em'
              placeholder='Browse'
              allowCount={1}
            />

            <Flex justifyContent='flex-end'>
              <Button variant='secondary' onClick={onClose}>
                Cancel
              </Button>
              <Button ml='1em' type='submit' disabled={isSubmitting}>
                Submit
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default ReportAnIssueModal
