import styled from 'styled-components'

const CardTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-family: ${({ theme }) => theme.fonts.Montserrat};
  border-left: 5px solid ${({ theme }) => theme.colors.primary.default};
  padding: 0 0.5em;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.grayscale.dark};
  margin: ${({ margin }) => margin || '1em'};
`

export default CardTitle
