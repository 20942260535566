import styled from 'styled-components'

const ErrorText = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  font-size: ${({ theme }) => theme.fontSizes.xxsmall};
  font-style: italic;
  color: ${({ theme }) => theme.colors.statusFill.danger};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

export default ErrorText
