import styled from 'styled-components'
import { Box } from '@fullfacing/schoolbus'

//TODO: Move to schoolbus
const AlertBox = styled(Box).attrs(props => ({
  p: '0.5em',
  borderRadius: 'full',
  ...props
}))`
  border: 1px solid ${({ color }) => color};
  font-style: italic;
  font-family: ${({ theme }) => theme.fonts.Montserrat};
  font-size: ${({ theme }) => theme.fontSizes.small};

  ${({ theme, danger }) =>
    danger &&
    `
    text-align: center;
    background-color: ${theme.colors.statusFill.errorLight};
    color: ${theme.colors.statusText.error};
    border: none;
  `}
`

export default AlertBox
