import { useState } from 'react'
import { Toaster } from 'react-hot-toast'

import Toast from '@lib/common/toast'
import ReportAnIssueModal from '@lib/modals/report-an-issue-modal'

const CustomToaster = () => {
  const [details, setDetails] = useState(null)

  return (
    <>
      <Toaster position='top-right'>
        {toast => 
          <Toast toast={toast} onReportEvent={setDetails} />
        }
      </Toaster>
      <ReportAnIssueModal
        details={details}
        isOpen={!!details}
        onClose={() => setDetails(null)}
      />
    </>
  )
}

export default CustomToaster
