import {
  userRoles,
  learnerRoles,
  adminRoles,
  managementRoles
} from '@lib/config/constants'

const MANAGEMENT_PORTAL = 'MP'
const HEAD_OFFICE = 'HO'
const CLASSROOM_MANAGER = 'CM'
const MODERATOR_PORTAL = 'ModP'
const INDIVIDUAL_TRAINING = 'IT'

export default function hasValidRole (
  roles = [],
  platform = MANAGEMENT_PORTAL
) {
  const { userModerator } = userRoles
  const { adminSuper, adminAccount } = managementRoles

  const validRoles = {
    [MANAGEMENT_PORTAL]: Object.values(adminRoles),
    [HEAD_OFFICE]: Object.values(managementRoles),
    [CLASSROOM_MANAGER]: [adminSuper, adminAccount],
    [MODERATOR_PORTAL]: [userModerator],
    [INDIVIDUAL_TRAINING]: Object.values(learnerRoles)
  }
  const platformRoles = validRoles[platform] || []
  return roles.some(role => platformRoles.includes(role))
}
