import styled from 'styled-components'
import { Flex, UnstyledButton, theme } from '@fullfacing/schoolbus'

export const Logo = styled.div`
  background-image: url('https://cdn.fullfacing.io/images/e6402da0-8483-4e5c-8f71-601403bc05e2.svg');
  height: 50px;
  width: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`

export const LogoContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`

export const LogoTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  margin-left: 0.2em;
  color: ${({ theme }) => theme.colors.grayscale.light};
  text-transform: uppercase;
`

export const Icon = styled(UnstyledButton)`
  height: 40px;
  width: 40px;
  background-size: contain;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 1.5em;

  &:hover {
    opacity: 0.9;
  }
`

export const Header = styled(Flex).attrs({
  as: 'header',
  alignItems: 'center',
  justifyContent: 'space-between'
})`
  min-height: 90px;
  flex-wrap: wrap;
  padding-bottom: 0.5em;

  @media (max-width: 940px) {
    flex-direction: column;
  }
`

export const HeaderOptions = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center'
})`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

export const ActiveUser = styled.p`
  color: ${({ theme }) => theme.colors.grayscale.light};
  font-weight: ${({ theme }) => theme.fontWeights.semi};
  margin: 0 1em;
  text-align: center;
  cursor: default;
`

export const styleOverrides = {
  popup: {
    padding: '1em',
    width: '13em',
    borderRadius: theme.radii.medium
  }
}
