import styled from 'styled-components'
import { Flex } from '@fullfacing/schoolbus'

export const ModalHeader = styled(Flex).attrs({
  as: 'header',
  alignItems: 'center',
  justifyContent: 'space-between',
  mb: '1em'
})``

export default ModalHeader
