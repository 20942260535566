import styled from 'styled-components'
import { Flex } from '@fullfacing/schoolbus'

const DetailsForm = styled(Flex).attrs({
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center'
})`
  @media (max-width: 990px) {
    flex-direction: column;
  }
`

export default DetailsForm
