import styled from 'styled-components'

const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.grayscale.xxlight};
  border-radius: ${({ theme }) => theme.radii.xxlarge};
  box-shadow: ${({ theme }) => theme.shadows.cardContainer};
  flex: 1;
`

export default Content
