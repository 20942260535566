import styled from 'styled-components'

const CopyrightText = styled.p`
  text-align: center;
  margin: 2em 0 0;
  color: ${({ theme }) => theme.colors.grayscale.default};
  font-size: 13px;
  font-family: ${({ theme }) => theme.fonts.Montserrat};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

export default CopyrightText
