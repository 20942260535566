export const userRoles = {
  userAssessor: 'user-assessor',
  userModerator: 'user-moderator',
  userFacilitator: 'user-facilitator',
  userContentApproval: 'user-content',
  userOps: 'user-ops',
  userTrainer: 'user-trainer'
}

export const adminRoles = {
  adminSuper: 'admin-super'
}

export const managementRoles = {
  adminSuper: 'admin-super',
  adminClient: 'admin-client',
  adminAccount: 'admin-account'
}

export const learnerRoles = {
  learnerPermanent: 'learner-permanent',
  learnerTemporary: 'learner-temporary',
  learnerApplicant: 'learner-applicant'
}

export const roles = {
  ...adminRoles,
  ...managementRoles,
  ...learnerRoles,
  ...userRoles
}

export const contentStates = {
  unpublished: 'Unpublished',
  pending: 'Pending',
  approved: 'Approved',
  published: 'Published',
  declined: 'Declined',
  archived: 'Archived',
  expired: 'Expired',
  missed: 'Missed'
}

export const HLS_ENCODING_TYPE = 'application/x-mpegURL'

export const DEFAULT_COVER =
  'https://cdn.fullfacing.io/images/9c107aef-874f-4d47-94fa-c5a8c6c1bb37.svg'

export const DEFAULT_LANGUAGE = 'en-ZA'

export const PAGE_PERMISSION_MAP = {
  '/dashboard': 'dashboard',
  '/branches': 'sites',
  '/learners': 'learners',
  '/library': 'library',
  '/organiser': 'organiser',
  '/scheduler': 'scheduler',
  '/reports': 'reporting'
}

export const UNRESTRICTED_PAGES = ['/404']

export const MAX_DESCRIPTION_LENGTH = 300

export const ACCESS_LEVELS = {
  noAccess: 'NoAccess',
  fullAccess: 'FullAccess',
  viaEngagement: 'ViaEngagement'
}

export const COMMON_IMAGES = {
  screeningIcon: 'https://cdn.fullfacing.io/images/be612601-4e1a-4fff-9291-681509068cc8.svg',
  rolesIcon: 'https://cdn.fullfacing.io/images/f9f81f05-e961-4fee-a83d-f8ba64be8feb.svg',
  onboardingIcon: 'https://cdn.fullfacing.io/images/4b76a597-4398-4ab0-a854-a873c7ffd91d.svg',
  formalIcon: 'https://cdn.fullfacing.io/images/6eb8862b-976a-44fb-9ec3-bdefc00fc46e.svg',
  surveysIcon: 'https://cdn.fullfacing.io/images/21b13afa-a7e2-49b9-945d-228801b0c8f8.svg',
  peerReviewIcon: 'https://cdn.fullfacing.io/images/53dc2940-b279-42f8-91f3-85f30c8a310c.svg',
  distributionsIcon: 'https://cdn.fullfacing.io/images/e97dedc5-71b9-4627-aac4-5339c04a75a7.svg',
  practicalAssessmentIcon: 'https://cdn.fullfacing.io/images/d6d419a2-707f-45bf-9e30-09ff83a326c8.svg',
  theoreticalAssessmentIcon: 'https://cdn.fullfacing.io/images/c8a8784b-2a9b-4e34-89a0-68e4e85dab60.svg',
  lockedIcon: 'https://cdn.fullfacing.io/images/ee0b06b6-6e6f-4882-9c79-2754723a5bf3.svg',
  videoIcon: 'https://cdn.fullfacing.io/images/84812a71-65f3-4dc0-8d51-38b40492b8e0.svg',
  imageIcon: 'https://cdn.fullfacing.io/images/79be608e-6e44-4560-8bae-108919483902.svg',
  audioIcon: 'https://cdn.fullfacing.io/images/b7a636b1-4463-43ce-b8f9-b1c8a6f4d0b6.svg',
  confirmationIcon: 'https://cdn.fullfacing.io/images/f70a7ae2-cf30-4f92-9bc3-3324dfa7b1ab.svg',
  documentIcon: 'https://cdn.fullfacing.io/images/88335294-9a64-4ebe-8f7e-c75a0c4e1abb.svg',
  textIcon: 'https://cdn.fullfacing.io/images/ec24edd9-8e01-4a97-a0a2-442cd141bb1a.svg'
}

export const REPORT_TEMPLATE_URI =
  'https://cdn.fullfacing.io/documents/6c462dbd-0d4f-4b77-b3b2-06ac37f1ed77.pdf'
export const PDF_WORKER_URI =
  'https://cdn.fullfacing.io/documents/cc1a55c2-8f14-47d6-a671-89393b20476b.js'

export const MAIL_RECIPIENT = 'learn@lessondesk.com'
export const REPORT_ISSUE_RECIPIENT = 'ldsupport@lessondesk.com'

export const DAYS_IN_WEEK = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
]

const DAY_SUFFIXES = ['th', 'st', 'nd', 'rd']

function getDaySuffix (day) {
  if (day >= 11 && day <= 13) return 'th'

  const lastDigit = day % 10
  return DAY_SUFFIXES[lastDigit] || 'th'
}

export const DAYS_IN_MONTH = Array.from({ length: 31 }, (_, i) => `${i + 1}${getDaySuffix(i + 1)}`)

export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export const UPLOAD_TYPE_MAP = {
  documents: ['.doc', '.docx', '.pdf', '.xls', '.xlsx', '.csv'],
  images: ['.jpg', '.jpeg', '.png']
}

export const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

export const PLATFORM_MAP = {
  HO: 'HO',
  'Class Manager': 'CM'
}

export const ENGLISH_LANGUAGE_HEADER = { 'Accept-Language': 'en-ZA' }

export const MODAL_TABLE_ACTIONS_PROPS = {
  tableActionsProps: {
    selectProps: { menuPosition: 'fixed' }
  }
}

export const BASE_URL = `${process.env.NEXT_PUBLIC_API_SCHEME}://${process.env.NEXT_PUBLIC_API_HOST}:${process.env.NEXT_PUBLIC_API_PORT}`
export const CMS_URL = `${process.env.NEXT_PUBLIC_CMS_SCHEME}://${process.env.NEXT_PUBLIC_CMS_HOST}:${process.env.NEXT_PUBLIC_CMS_PORT}`
export const HO_URL = process.env.NEXT_PUBLIC_MANAGEMENT_REDIRECT_URL
export const CLASS_MANAGER_URL = process.env.NEXT_PUBLIC_CLASS_MANAGER_REDIRECT_URL
export const GROUP_GLOBAL_URL = process.env.NEXT_PUBLIC_GROUP_GLOBAL_REDIRECT_URL
export const MODERATOR_PORTAL_URL = process.env.NEXT_PUBLIC_MODERATOR_PORTAL_REDIRECT_URL
export const INDIVIDUAL_TRAINING_URL = process.env.NEXT_PUBLIC_INDIVIDUAL_TRAINING_REDIRECT_URL

export const CALENDAR_BOTTOM_PLACEMENT = {
  popperPlacement: 'bottom-start',
  popperProps: { positionFixed: 'true' },
  popperModifiers: {
    flip: { behavior: ['bottom'] },
    preventOverflow: { enabled: false },
    hide: { enabled: false }
  }
}

export const TRAINING_TYPES_WITHOUT_TIMEZONES = ['onboarding']

export const SWR_STATIC_CONFIG = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  onError: console.error,
  shouldRetryOnError: false
}

export const PASS_MARK_OPTIONS = Array.from({ length: 6 }, (_, i) => ({
  value: (5 + i) / 10,
  label: `${(5 + i) * 10}%`
}))

export const ATTEMPTS_OPTIONS = [
  ...Array.from({ length: 5 }, (_, i) => {
    const number = i + 1
    return {
      label: number,
      value: number
    }
  }),
  {
    label: 'Unlimited',
    value: 0
  }
]
