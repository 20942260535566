import accountContainer from '@lib/containers/account'
import userContainer from '@lib/containers/user'
import {
  ACCESS_LEVELS,
  PAGE_PERMISSION_MAP,
  UNRESTRICTED_PAGES,
  roles
} from '@lib/config/constants'

export const BACKEND_LICENSE_KEY_MAP = {
  platforms: {
    mobile: 'Mobile',
    web: 'Web',
    group: 'Group'
  },
  contentTypes: {
    onboarding: 'Onboarding',
    formalTraining: 'Formal',
    standardSurveys: 'Survey',
    peerReviews: 'Review'
  },
  organiserContent: {
    roles: 'Role',
    screening: 'Screening',
    onboarding: 'Onboarding',
    formal: 'Formal',
    survey: 'Survey',
    distributions: 'Distribution'
  }
}

export function getLicenses (module) {
  const { noAccess, fullAccess, viaEngagement } = ACCESS_LEVELS
  const { licenses } = accountContainer.state
  const { content = [], platforms } = licenses
  const { accessLevel } = content.find(({ type }) => type === module) || {}

  const hasNoAccess = accessLevel ? accessLevel === noAccess : true
  const hasFullAccess = accessLevel === fullAccess
  const hasViaEngagement = accessLevel === viaEngagement

  const hasAtleastEngagements = [fullAccess, viaEngagement].includes(
    accessLevel
  )

  return {
    hasFullAccess,
    hasNoAccess,
    hasViaEngagement,
    hasAtleastEngagements,
    accessLevel,
    platforms
  }
}

export function getPermissions (entity) {
  const { permissions } = userContainer.state
  const { scopes } = permissions

  const isAdmin = isClientAdmin()
  const canView = isAdmin || checkView(scopes, entity)
  const canWrite = isAdmin || checkWrite(scopes, entity)
  const canDelete = isAdmin || checkDelete(scopes, entity)

  return { canView, canWrite, canDelete }
}

export function canViewRoute (route) {
  if (isClientAdmin()) return true
  const { permissions } = userContainer.state
  const permissionIndex = Object.entries(PAGE_PERMISSION_MAP).findIndex(
    ([key]) => route.startsWith(key)
  )
  const permission = Object.values(PAGE_PERMISSION_MAP)[permissionIndex]

  if (!permission) {
    return UNRESTRICTED_PAGES.some(page => route.startsWith(page))
  }

  return checkView(permissions?.scopes, permission)
}

export function isAccountAdmin (userRoles) {
  const roles = userRoles || userContainer.state.roles
  return isClientAdmin() || roles.some(role => role === 'admin-account')
}

export function isClientAdmin (userRoles) {
  const roles = userRoles || userContainer.state.roles
  return roles.some(role => role === 'admin-client' || role === 'admin-super')
}

export function isSuperAdmin (userRoles) {
  const roles = userRoles || userContainer.state.roles
  return roles.some(role => role === 'admin-super')
}

export function checkView (scopes, entity) {
  return (
    scopes?.includes(`${entity}-view`) ||
    scopes?.includes(`${entity}-write`) ||
    scopes?.includes(`${entity}-delete`)
  )
}

export function checkWrite (scopes, entity) {
  return (
    scopes?.includes(`${entity}-write`) || scopes?.includes(`${entity}-delete`)
  )
}

export function checkDelete (scopes, entity) {
  return scopes?.includes(`${entity}-delete`)
}

export function getHighestRoleRank () {
  const { roles: userRoles = [] } = userContainer.state
  const validRoles = [
    { key: roles.adminSuper, value: 'super admin' },
    { key: roles.adminClient, value: 'client admin' },
    { key: roles.adminAccount, value: 'account admin' }
  ]
  const validRole = validRoles.find(({ key }) => userRoles.includes(key))
  return validRole?.value || 'N/A'
}

export function highestPermissions () {
  const {
    userAssessor,
    userModerator,
    userFacilitator,
    userContentApproval
  } = roles

  return {
    scopes: [
      'reporting-delete',
      'learners-delete',
      'scheduler-delete',
      'library-delete',
      'sites-delete',
      'organiser-delete',
      'dashboard-delete'
    ],
    roles: [userAssessor, userModerator, userFacilitator, userContentApproval]
  }
}
