import mediaSpecMap from '@lib/utils/media-spec-map'

const {
  videoSpec,
  documentSpec,
  pdfSpec,
  imageSpec,
  svgSpec,
  signatureImageSpec
} = mediaSpecMap

const TWENTY_MB = 20 * 1024 * 1024
const ONE_GB = 1024 * 1024 * 1024
const THREE_GB = ONE_GB * 3
const MIN_HORIZONTAL_IMAGE_SIZE = 800
const MIN_VERTICAL_IMAGE_SIZE = 600
const MAX_HORIZONTAL_IMAGE_SIZE = 1920
const MAX_VERTICAL_IMAGE_SIZE = 1080

const createInstruction = type =>
  `In order for the ${type} to display on Lesson Desk`

function isAboveSizeLimit ({ size }, maxSize = TWENTY_MB) {
  return (typeof size !== 'number' || size > maxSize)
}

function imageValidator (file) {
  return new Promise(resolve => {
    const image = new Image()
    const errors = []
    const successes = []

    image.onload = () => {
      const { naturalHeight, naturalWidth } = image
      const format = file.name?.split('.').pop()

      if (['png,jpg,jpeg'].includes(format)) errors.push('Format')
      else successes.push('Format')
      if (naturalWidth / naturalHeight < 1) errors.push('Aspect ratio')
      else successes.push('Aspect ratio')
      if (
        naturalWidth < MIN_HORIZONTAL_IMAGE_SIZE ||
        naturalHeight < MIN_VERTICAL_IMAGE_SIZE ||
        naturalWidth > MAX_HORIZONTAL_IMAGE_SIZE ||
        naturalHeight > MAX_VERTICAL_IMAGE_SIZE
      ) {
        errors.push('Resolution')
      } else successes.push('Resolution')

      return resolve({ errors, successes })
    }

    image.src = URL.createObjectURL(file)
  })
}

function videoValidator (file) {
  return new Promise(resolve => {
    const errors = []
    const successes = []
    const { type } = file
    const video = window.document.createElement('video')
    const RESOLUTION = { width: 1920, height: 1080 }

    video.onerror = (error) => {
      console.error(error)
      resolve({ errors: [], successes: ['Resolution', 'Format', 'Codec'] })
    }
    video.onloadedmetadata = () => {
      const { videoWidth, videoHeight } = video

      if (type !== 'video/mp4') errors.push('Format')
      else successes.push('Format')

      if (videoWidth === 0 && videoHeight === 0) errors.push('Codec')
      else if (
        videoWidth !== RESOLUTION.width ||
        videoHeight !== RESOLUTION.height
      ) {
        errors.push('Resolution')
      } else successes.push('Resolution')

      if (isAboveSizeLimit(file, THREE_GB)) errors.push('Maximum file size')
      else successes.push('Maximum file size')

      resolve({ errors, successes })
    }
    video.src = URL.createObjectURL(file)
  })
}

function pdfValidator (file = {}) {
  if (isAboveSizeLimit(file, TWENTY_MB)) {
    return {
      errors: ['Maximum file size'],
      successes: ['Format']
    }
  }
  return {
    errors: [],
    successes: ['Maximum file size', 'Format']
  }
}

function svgValidator (file) {
  return new Promise(resolve => {
    const image = new Image()
    const errors = []
    const successes = []

    image.onload = () => {
      const format = file.name?.split('.').pop()
      if (!['svg'].includes(format)) errors.push('Format')
      else successes.push('Format')

      return resolve({ errors, successes })
    }

    image.src = URL.createObjectURL(file)
  })
}

function signatureImageValidator (file) {
  return new Promise(resolve => {
    const image = new Image()
    const errors = []
    const successes = []

    image.onload = () => {
      const { naturalHeight, naturalWidth } = image
      const format = file.name?.split('.').pop()

      if (['png,jpg,jpeg'].includes(format)) errors.push('Format')
      else successes.push('Format')
      if (naturalWidth / naturalHeight < 1) errors.push('Aspect ratio')
      else successes.push('Aspect ratio')

      return resolve({ errors, successes })
    }

    image.src = URL.createObjectURL(file)
  })
}

export const image = {
  mediaName: 'image',
  specInstruction: createInstruction('image'),
  mediaCriteria: imageSpec,
  accept: '.png,.jpg,.jpeg',
  validator: imageValidator
}

export const video = {
  mediaName: 'video',
  specInstruction: createInstruction('video'),
  mediaCriteria: videoSpec,
  validator: videoValidator,
  accept: 'video/mp4',
  loadingTitle: 'Video upload in progress',
  loadingType: 'video file',
  loadingImage:
    'https://cdn.fullfacing.io/images/24d2e9b6-7279-4ca2-a975-2233cc0df4f1.svg'
}

export const pdf = {
  mediaName: 'PDF',
  specInstruction: createInstruction('PDF'),
  mediaCriteria: pdfSpec,
  accept: '.pdf',
  loadingTitle: 'Document upload in progress',
  loadingType: 'PDF document',
  loadingImage:
    'https://cdn.fullfacing.io/images/dd475ca7-bc70-44ac-910a-649a7123ad32.svg',
  validator: pdfValidator
}

export const document = {
  mediaName: 'document',
  specInstruction: createInstruction('document'),
  mediaCriteria: documentSpec,
  accept: '.pdf',
  validator: pdfValidator
}

export const newsflash = {
  mediaName: 'newsflash',
  specInstruction: createInstruction('newsflash'),
  mediaCriteria: documentSpec
}

export const zip = {
  mediaName: 'ZIP',
  specInstruction: createInstruction('zip'),
  accept: 'application/zip',
  title: 'Import ZIP package'
}

export const svg = {
  mediaName: 'images',
  accept: '.svg',
  mediaCriteria: svgSpec,
  validator: svgValidator
}

export const signatureImage = {
  mediaName: 'images',
  accept: '.png,.jpg,.jpeg',
  mediaCriteria: signatureImageSpec,
  validator: signatureImageValidator
}
