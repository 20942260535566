import styled from 'styled-components'
import { Flex, Button } from '@fullfacing/schoolbus'

export const ProfileDropdownContainer = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
})``

export const ProfileFullName = styled.h3`
  margin: 1em 0 0.5em;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ theme }) => theme.fonts.Montserrat};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.black};
`

export const ProfileInitials = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.extrabold};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.primary.default};
  padding: 0.6em;
  background-color: rgba(242, 168, 59, 0.15);
  border-radius: ${({ theme }) => theme.radii.full};
  font-family: ${({ theme }) => theme.fonts.Montserrat};
`

export const MenuButton = styled(Button).attrs({
  type: 'button'
})`
  text-decoration: none;
`
