export const NAV_ITEMS = [
  { name: 'Dashboard', href: '/dashboard' },
  { name: 'Branches', href: '/branches' },
  { name: 'Learners', href: '/learners' },
  { name: 'Library', href: '/library' },
  { name: 'Organiser', href: '/organiser/roles' },
  { name: 'Scheduler', href: '/scheduler/individual/onboarding/permanent' },
  { name: 'Reports', href: '/reports' }
]

export const KEYCLOAK_TOKEN_KEY = 'kc_token'
export const KEYCLOAK_REFRESH_TOKEN = 'kc_refresh_token'
export const ANALYTICS_KEY = 'G-7X0VNDXSDP'

export const FLOW_TYPE_STATIC_PATHS = {
  paths: [{ params: { flowType: 'add' } }, { params: { flowType: 'edit' } }],
  fallback: false
}
