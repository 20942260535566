import { useRef, useEffect } from 'react'
import { toast as reactToast } from 'react-hot-toast'
import { captureException } from '@sentry/nextjs'
import { Flex, Button, Text, Spinner, theme } from '@fullfacing/schoolbus'
import CheckIcon from '@fullfacing/material-icons/dist/CheckCircle'

const TYPE_MAP = {
  success: 'success',
  loading: 'loading'
}

const Toast = ({ toast = {}, onReportEvent }) => {
  const { error, type, message, action, callback } = toast
  const { onClick, text } = action || {}
  const eventId = useRef(null)

  const isSuccessType = type === TYPE_MAP.success
  const isLoadingType = type === TYPE_MAP.loading

  const hasIcon = isSuccessType || isLoadingType

  useEffect(() => {
    if (error) {
      const sentryEventId = captureException(error)
      eventId.current = sentryEventId
    }

    return () => callback && callback()
  }, [toast?.id, error, callback])

  const handleReportClick = ({ id, errorType }) => {
    onReportEvent({ eventId: eventId.current, errorType })
    reactToast.remove(id)
  }

  return (
    <Flex
      p='1em 2em'
      borderRadius='0.5em'
      bg='rgba(0, 0, 0, 0.9)'
      alignItems='center'
      maxWidth='min(50rem, 90vw)'
      {...hasIcon && { pl: '1em' }}
    >
      {isSuccessType && (
        <CheckIcon color={theme.colors.statusFill.success} />
      )}
      {isLoadingType && (
        <Spinner size='1.3em' />
      )}
      <Text color='white' fontSize='medium' {...hasIcon && { ml: '1em' }}>
        {error && 'Oops!'} {message}
      </Text>
      {error && (
        <Button
          size='mini'
          ml='1em'
          onClick={() => handleReportClick(toast)}
          uppercase
        >
          Report issue
        </Button>
      )}
      {action && (
        <Button size='mini' ml='1em' onClick={onClick} uppercase>
          {text}
        </Button>
      )}
    </Flex>
  )
}

export default Toast
