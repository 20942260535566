import styled from 'styled-components'

const CardDescription = styled.p`
  margin: 1em 1em 2em;
  padding-bottom: 2em;
  border-bottom: 2px solid ${({ theme }) => theme.colors.grayscale.xxlight};
  color: ${({ theme }) => theme.colors.grayscale.dark};
  font-weight: ${({ theme }) => theme.fontWeights.light};
  font-size: ${({ theme }) => theme.fontSizes.small};
  padding-right: 50px;
`

export default CardDescription
