import { Container } from '@lib/utils/stateful'
import Stoor from 'stoor'

const ACTIVE_USER_KEY = 'active-user'
const USER_NAMESPACE_KEY = 'user'

class UserContainer extends Container {
  constructor () {
    super()

    this.userStorage = new Stoor({ namespace: USER_NAMESPACE_KEY, storage: 'local' })

    const {
      id,
      accountId,
      clientId,
      email,
      groupIds,
      locale,
      name,
      surname,
      scope,
      roles,
      username,
      permissions,
      timeZone
    } = this.userStorage.get(ACTIVE_USER_KEY, {
      id: '',
      accountId: '',
      clientId: [],
      email: '',
      groupIds: [],
      locale: '',
      name: '',
      scope: '',
      roles: [],
      surname: '',
      username: '',
      permissions: {},
      timeZone: ''
    }) || {}

    this.state = {
      id,
      accountId,
      clientId,
      email,
      groupIds,
      locale,
      scope,
      roles,
      name,
      surname,
      username,
      permissions,
      timeZone
    }
  }

  set ({
    id,
    accountId,
    clientId,
    email,
    groupIds,
    locale,
    roles,
    scope,
    name,
    surname,
    username,
    permissions,
    timeZone
  }) {
    return this.setState(
      {
        id,
        accountId,
        clientId,
        email,
        groupIds,
        locale,
        name,
        surname,
        scope,
        roles,
        username,
        permissions,
        timeZone
      },
      state => {
        this.userStorage.set(ACTIVE_USER_KEY, { ...state })
      }
    )
  }
}

const user = new UserContainer()

export default user
