import Head from 'next/head'

const PageMetaData = ({ title = 'Lesson Desk | Head Office', description }) => (
  <Head>
    <title>{title}</title>
    {description && (
      <description>
        {description}
      </description>
    )}
  </Head>
)

export default PageMetaData