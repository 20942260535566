const messages = {
  tooShort: {
    simple: 'Too short',
    verbose: 'Must be at least 2 characters'
  },
  tooLong: (max = 40) => ({
    simple: 'Too long',
    verbose: `Must be at most ${max} characters`
  }),
  telTooShort: {
    simple: 'Too short',
    verbose: 'Must be at least 10 characters'
  },
  required: 'Required',
  translationRequired: {
    simple: 'Translations required',
    verbose: 'Must have a translation for all the selected languages'
  },
  phoneNumber: 'Invalid phone number',
  email: 'Email address is not valid',
  fileSize: {
    simple: 'Too large',
    verbose: 'File size is too large'
  },
  fileType: 'Unsupported File Format',
  invalidExpiryDate: {
    simple: 'Invalid date',
    verbose: 'Must be after publish date'
  },
  futureDate: 'Must be a future date',
  futureTime: 'Must be a future time',
  expiryDate: 'Must be after publish date',
  endDate: 'Must be after start date',
  minFiveMinutes: 'Minimum 5 min after',
  minFifteenMinutes: 'Minimum 15 min after',
  pastDate: 'Must be a past date',
  periodPassed: 'Selected period already in past',
  delimiter: {
    simple: 'Invalid delimiter',
    verbose: 'Delimiter can only be 1 character'
  },
  confirmPassword: 'Passwords do not match',
  passwordLowerCaseRequirement: {
    simple: 'Min 2 lowercase letters',
    verbose: 'Must contain at least 2 lowercase characters'
  },
  passwordUpperCaseRequirement: {
    simple: 'Min 1 uppercase character',
    verbose: 'Must contain at least 1 uppercase character'
  },
  passwordNumberRequirement: {
    simple: 'Min 1 numeric character',
    verbose: 'Must contain at least 1 numeric character'
  },
  passwordSpecialCharacter: {
    simple: 'Min 1 special character',
    verbose: 'Must contain at least 1 special character'
  },
  passwordUsernameMatch: {
    simple: 'Cannot match username',
    verbose: 'Must not be the same as your username'
  },
  reportToDate: 'Must be on or after `From date`',
  tooLow: (min = 0) => ({
    simple: 'Too low',
    verbose: `Must be ${min} or more`
  }),
  tooHigh: (max = 0) => ({
    simple: 'Too high',
    verbose: `Must not be more than ${max}`
  }),
  unsavedValues: 'Unsaved values',
  invalidUrl: 'Invalid url',
  integer: {
    simple: 'Invalid',
    verbose: 'Must be a whole number'
  }
}

export default messages
