import styled from 'styled-components'

export const FadeOut = styled.div`
  position: absolute;
  bottom: 1em;
  height: 150px;
  width: 100%;
  pointer-events: none;
  background: ${props =>
    props.background ||
    `linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.69) 50%,
    #ffffff 100%
  )`};
  left: 0;
`

export default FadeOut
