import styled from 'styled-components'

const Badge = styled.div`
  padding: 0.2em 0.5em;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.grayscale.xlight};
  color: ${({ color, theme }) => color || theme.colors.black};
  font-weight: ${({ theme }) => theme.fontWeights.semi};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  border-radius: 20px;
  min-width: 50px;
  margin: 0 0.5em;
`

export default Badge
