import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'

import { isClientAdmin } from '@lib/utils/permissions'

import { PLATFORM_MAP } from '@lib/config/constants'

import {
  ProfileDropdownContainer,
  ProfileFullName,
  ProfileInitials,
  MenuButton
} from './styles'

const ReportAnIssueModal = dynamic(() =>
  import('@lib/modals/report-an-issue-modal')
)
const AccountSelectModal = dynamic(() =>
  import('@lib/modals/account-select-modal')
)
const ProfileModal = dynamic(() => import('@lib/modals/my-profile-modal'))

const ProfileMenu = ({
  onClose,
  onLogout,
  platformName,
  userName,
  initials
}) => {
  const [showReportAnIssueModal, setShowReportAnIssueModal] = useState(false)
  const [showAccountSelectModal, setShowAccountSelectModal] = useState(false)
  const [showProfileModal, setShowProfileModal] = useState(false)

  const { events } = useRouter()

  useEffect(() => {
    events.on('routeChangeStart', onClose)

    return () => {
      events.off('routeChangeStart', onClose)
    }
  }, [events, onClose])

  const isAdminUser = isClientAdmin()
  const isHO = PLATFORM_MAP[platformName] === PLATFORM_MAP.HO
  const isClassroom =
    PLATFORM_MAP[platformName] === PLATFORM_MAP['Class Manager']

  const handleToggleReportAnIssue = () => {
    if (onClose) onClose()
    setShowReportAnIssueModal(true)
  }

  const handleAccountSelectModal = () => {
    onClose()
    setShowAccountSelectModal(true)
  }

  const handleMyProfileModal = () => {
    onClose()
    setShowProfileModal(true)
  }

  const handleLogout = () => {
    window.localStorage.clear()
    window.sessionStorage.clear()

    onLogout()
    onClose()
  }

  return (
    <>
      <ProfileDropdownContainer>
        <ProfileInitials>{initials}</ProfileInitials>
        <ProfileFullName>{userName}</ProfileFullName>

        <MenuButton
          variant='secondary'
          shape='block'
          mt='0.5em'
          onClick={() => handleMyProfileModal(onClose)}
        >
          View my profile
        </MenuButton>

        {isAdminUser && isHO && (
          <MenuButton
            variant='secondary'
            shape='block'
            mt='0.5em'
            href='/tag-manager'
          >
            Client tag manager
          </MenuButton>
        )}

        {isAdminUser && !isClassroom && (
          <MenuButton variant='secondary' shape='block' mt='0.5em' href='/'>
            Change accounts
          </MenuButton>
        )}

        {isAdminUser && isHO && !isClassroom && (
          <MenuButton
            variant='secondary'
            shape='block'
            mt='0.5em'
            onClick={() => handleAccountSelectModal(onClose)}
          >
            Manage users
          </MenuButton>
        )}

        <MenuButton
          variant='secondary'
          shape='block'
          mt='0.5em'
          onClick={() => handleToggleReportAnIssue(onClose)}
        >
          Report an issue
        </MenuButton>

        <MenuButton
          onClick={() => handleLogout()}
          variant='primary'
          shape='block'
          mt='0.5em'
        >
          Sign out
        </MenuButton>
      </ProfileDropdownContainer>

      {showReportAnIssueModal && (
        <ReportAnIssueModal
          isOpen
          onClose={() => setShowReportAnIssueModal(false)}
        />
      )}

      {showAccountSelectModal && (
        <AccountSelectModal
          isOpen
          onClose={() => setShowAccountSelectModal(false)}
        />
      )}

      {showProfileModal && (
        <ProfileModal isOpen onClose={() => setShowProfileModal(false)} />
      )}
    </>
  )
}

export default ProfileMenu
