import styled from 'styled-components'

export const ModalCloseButton = styled.button.attrs({
  type: 'button'
})`
  border: none;
  color: ${({ theme }) => theme.colors.grayscale.light};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  background-color: ${({ theme }) => theme.colors.transparent};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  cursor: pointer;
  z-index: 1;
`

export default ModalCloseButton
