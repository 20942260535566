import styled from 'styled-components'

export const ModalTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-family: ${({ theme }) => theme.fonts.Montserrat};
  color: ${({ theme }) => theme.colors.grayscale.dark};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

export default ModalTitle
