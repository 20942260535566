import { Container } from '@lib/utils/stateful'
import Stoor from 'stoor'
import { nanoid } from 'nanoid'

import userContainer from '@lib/containers/user'
import { isSuperAdmin } from '@lib/utils/permissions'

const CLIENT_NAMESPACE_KEY = 'client'
const ACTIVE_CLIENT_KEY = 'active-client'

const initialValues = {
  id: '',
  active: false,
  name: '',
  supportNumber: '',
  website: '',
  address: {},
  contact: {},
  renderId: nanoid()
}

class ClientContainer extends Container {
  constructor () {
    super()

    this.storage = new Stoor({ namespace: CLIENT_NAMESPACE_KEY })

    const {
      id,
      active,
      name,
      description,
      contact,
      supportNumber,
      website,
      address,
      renderId,
      accounts
    } = this.storage.get(ACTIVE_CLIENT_KEY, initialValues)

    this.state = {
      id,
      active,
      name,
      contact,
      description,
      supportNumber,
      website,
      address,
      renderId,
      accounts
    }
  }

  set ({ clearPrevious = false, ...newClient } = {}) {
    const { clientId } = userContainer.state
    const { id } = newClient
    if (clientId !== id && !isSuperAdmin()) {
      throw Error(`Invalid clientId, ${id}`)
    }

    const newState = {
      ...initialValues,
      ...newClient
    }

    return this.setState(newState, () => {
      this.storage.set(ACTIVE_CLIENT_KEY, newState)
    })
  }
}

const client = new ClientContainer()

export default client
