import { useState, useEffect } from 'react'
import Router, { useRouter } from 'next/router'
import { configureScope } from '@sentry/nextjs'
import dynamic from 'next/dynamic'
import toast from 'react-hot-toast'
import { withKeycloak } from '@fullfacing/ff-web-next-utils/keycloak'
import { Footer } from '@fullfacing/schoolbus'
import { PageProgressMethods } from '@fullfacing/ff-web-next-utils'

import Header from '@lib/common/header'
import { Wrapper, Content } from '@lib/styled'
import userContainer from '@lib/containers/user'
import clientContainer from '@lib/containers/client'
import accountContainer from '@lib/containers/account'
import languagesContainer from '@lib/containers/languages'
import uploadResultsContainer from '@lib/containers/upload-results'

import ToastError from '@lib/utils/toast-error'
import hasValidRole from '@lib/utils/has-valid-role'
import objectHasEntries from '@lib/utils/object-has-entries'
import redirectToAllowedTab from '@lib/utils/redirect-to-allowed-tab'
import useContainerContext from '@lib/utils/use-container-context'
import {
  canViewRoute,
  isClientAdmin,
  isSuperAdmin,
  highestPermissions
} from '@lib/utils/permissions'

import api from '@api'
import PageLoader from '@lib/common/page-loader'

const UploadResultsToast = dynamic(() => import('@lib/common/upload-results-toast'))

const CM = 'CM'
const CLASSROOM_MANAGER = 'ClassroomManager'

const getLanguages = async () => {
  try {
    if (languagesContainer.state.languages.length === 0) {
      const languages = await api.clients.getLanguages()
      languagesContainer.set(languages)
    }
  } catch (error) {
    console.error(error)
    // If we fail to retrieve languages we shouldn't block the user
    // Fallback to dynamically importing a fallback
    const languages = await import('@lib/config/languages.json')
    languagesContainer.set(languages.default)
  }
}

const getUserRequestDetails = () => {
  const CLIENT_ID_REDIRECT_PATHS = ['/', '/dashboard']
  const { id: cachedClientId } = clientContainer.state
  const { id: cachedAccountId } = accountContainer.state
  const {
    clientId: assignedClientId,
    accountId: assignedAccountId
  } = userContainer.state
  const isOnDashboard = CLIENT_ID_REDIRECT_PATHS.some(
    path => window.location.pathname === path
  )
  const urlSearchParams = new URLSearchParams(window.location.search)
  const clientIdParam = urlSearchParams.get('clientId')
  const accountIdParam = urlSearchParams.get('accountId')

  const currentClientId = isSuperAdmin()
    ? cachedClientId || assignedClientId
    : assignedClientId
  const clientId = isOnDashboard
    ? clientIdParam || currentClientId
    : currentClientId

  const currentAccountId = isClientAdmin()
    ? cachedAccountId || assignedAccountId
    : assignedAccountId
  const accountId = isOnDashboard
    ? accountIdParam || currentAccountId
    : currentAccountId

  const alreadyHasClientInfo = cachedClientId === clientId
  const alreadyHasAccountInfo = cachedAccountId === accountId

  return {
    clientId,
    accountId,
    alreadyHasClientInfo,
    alreadyHasAccountInfo
  }
}

const getUser = async (userId, scope) => {
  if (userContainer.state.id === userId) return

  const [user, permissionResponse] = await Promise.all([
    api.users.getById(userId, { params: { inheritedRoles: true } }),
    api.users.getById(`${userId}/permissions`)
  ])

  const permissions = isClientAdmin(user.roles)
    ? highestPermissions()
    : permissionResponse

  return userContainer.set({ ...user, scope, permissions })
}

const getClient = async clientId => {
  const { clientId: assignedClientId } = userContainer.state
  const isValidRequest = clientId === assignedClientId || isSuperAdmin()
  if (!isValidRequest) {
    throw Error(`Invalid client request: ${clientId}`)
  }

  const client = await api.clients.getClientById(clientId)
  return clientContainer.set(client)
}

const getAccount = async ({ clientId, accountId, isClassroom }) => {
  const isOnAccountSelectDashboard =
    window.location.pathname === '/' && !isClassroom
  if (isOnAccountSelectDashboard) return accountContainer.clear()

  const { accountId: assignedAccountId } = userContainer.state
  const isValidRequest = accountId === assignedAccountId || isClientAdmin()
  if (!isValidRequest) {
    throw Error(`Invalid account request: ${accountId}`)
  }

  const {
    data: { data: accounts }
  } = await api.accounts.query({
    clientId,
    id: accountId
  })
  if (accounts.length < 1) {
    throw Error(
      `No matching account for accountId: ${accountId} and clientId: ${clientId}`
    )
  }

  const [account] = accounts
  return accountContainer.set(account)
}

const getLicenses = async () => {
  const { id: accountId } = accountContainer.state
  const { licenses: accountLicenses } = accountContainer.state
  const hasLicenses = !objectHasEntries(accountLicenses)
  if (!hasLicenses || !accountId) return

  // TODO: Figure out a better solution than querying all licenses
  const { data: licenseData = {} } = await api.licences.query({
    fields: 'id,content,platforms',
    accountId
  })

  const { data: licenses } = licenseData
  return accountContainer.set({
    ...accountContainer.state,
    licenses
  })
}

const validateRoles = platform => {
  const { roles } = userContainer.state
  if (!hasValidRole(roles, platform)) {
    throw new ToastError(
      'Invalid user role',
      'Your user does not have the required roles to access this site.'
    )
  }
}

const validatePlatform = () => {
  const { licenses } = accountContainer.state
  const { platforms } = licenses

  if (!platforms.includes(CLASSROOM_MANAGER)) {
    throw new ToastError(
      'Invalid platform access',
      'Your account does not have the required platform access for classroom manager.'
    )
  }
}

const Layout = ({ name, keycloak, platform, children }) => {
  const [initialized, setInitialized] = useState(false)
  const { uploadResults } = useContainerContext(uploadResultsContainer)
  const { permissions } = useContainerContext(userContainer)
  const { pathname } = useRouter()

  const handleLogout = () => {
    window.sessionStorage.clear()
    window.localStorage.clear()
    keycloak.logout()
  }

  const handlePagePermissionCheck = url => {
    if (platform === CM) return
    if (canViewRoute(url)) return
    try {
      redirectToAllowedTab()
    } catch (error) {
      console.error(error)
      handleLogout()
    }
  }

  const handleClientAdminRedirect = async () => {
    if (!accountContainer.state.id && window.location.pathname !== '/') {
      return Router.replace('/')
    }
  }

  /**
   * Initialize the languages, user and client containers and validate the user role.
   * Will only set if value isn't already present.
   */
  const getUserAndClientDetails = async () => {
    const { scope } = keycloak.tokenParsed
    const userId = keycloak.subject
    const isClassroom = platform === CM

    PageProgressMethods.start()

    getLanguages()
    await getUser(userId, scope)
    const {
      clientId,
      accountId,
      alreadyHasClientInfo,
      alreadyHasAccountInfo
    } = getUserRequestDetails()
    validateRoles(platform || name)
    if (!alreadyHasClientInfo) await getClient(clientId)
    if (!alreadyHasAccountInfo) { await getAccount({ clientId, accountId, isClassroom }) }
    await getLicenses()
    if (isClassroom) validatePlatform()

    configureScope(scope => {
      scope.setUser(userContainer.state)
    })

    PageProgressMethods.done()
  }

  useEffect(() => {
    const initialize = async () => {
      try {
        await getUserAndClientDetails()
        if (isClientAdmin()) await handleClientAdminRedirect()
        setInitialized(true)

        // TODO: Change to cancel redirect on routeChangeStart if this feature ever gets implemented:
        // https://github.com/vercel/next.js/issues/2476
        // Router.events.on('routeChangeComplete', handlePagePermissionCheck)
      } catch (error) {
        console.error(error)
        toast.error(error?.toast || 'Failed to get your user information.', {
          error
        })
        setTimeout(handleLogout, 3000)
      }
    }

    initialize()

    return () => {
      Router.events.off('routeChangeComplete', handlePagePermissionCheck)
    }
  }, [])

  useEffect(() => {
    if (permissions?.scopes) handlePagePermissionCheck(pathname)
  }, [pathname, permissions])

  if (!initialized) return <PageLoader />

  return (
    <Wrapper>
      <Header name={name} onLogout={handleLogout} />
      <Content>{initialized && children}</Content>
      <Footer />
      {uploadResults.length > 0 && <UploadResultsToast />}
    </Wrapper>
  )
}

export default withKeycloak(Layout)
