import { PageProgress } from '@fullfacing/ff-web-next-utils'
import { KeycloakProvider } from '@fullfacing/ff-web-next-utils/keycloak'
import { ThemeProvider } from 'styled-components'
import { configureScope } from '@sentry/nextjs'
import {
  GoogleAnalyticsV4,
  reportGaV4WebVitals,
  useGaV4PageViewAnalytics
} from '@fullfacing/ff-web-next-utils/analytics'
import { theme } from '@fullfacing/schoolbus'
import '@fullfacing/schoolbus/lib/index.css'

import Layout from '@lib/common/layout'
import GlobalStyles from '@lib/common/global'
import PageLoader from '@lib/common/page-loader'
import Toaster from '@lib/common/toaster'
import PageMetaData from '@lib/common/page-meta-data'
import { Provider } from '@lib/utils/stateful'
import { ANALYTICS_KEY } from '@config/constants'
import getEnvironment from '@lib/utils/get-environment'
import '@lib/common/page-loader/index.css'

if (process.env.NEXT_PUBLIC_SENTRY_DSN && process.env.NODE_ENV === 'production') {
  configureScope(scope => {
    scope.setTag('application', process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT_ID)
  })
}

export const reportWebVitals = reportGaV4WebVitals

const HO = ({ Component, pageProps, err }) => {
  // err is workaround for https://github.com/vercel/next.js/issues/8592
  useGaV4PageViewAnalytics(ANALYTICS_KEY)
  const env = getEnvironment()

  return (
    <KeycloakProvider loader={<PageLoader />}>
      <ThemeProvider theme={theme}>
        <PageMetaData />
        {env === 'prod' && (
          <GoogleAnalyticsV4 analyticsKey={ANALYTICS_KEY} />
        )}

        <GlobalStyles dark />
        <PageProgress color={theme.colors.primary.default} />
        <Toaster />

        <Provider>
          <Layout name='HO'>
            <Component {...pageProps} err={err} />
          </Layout>
        </Provider>
      </ThemeProvider>
    </KeycloakProvider>
  )
}

export default HO
