import styled from 'styled-components'
import { Flex } from '@fullfacing/schoolbus'

export default styled(Flex)`
  padding: 2em;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => theme.borders.light};
  box-shadow: ${({ theme }) => theme.shadows.toggle};
  border-radius: ${({ theme }) => theme.radii.large};
`
