import React from 'react'
import Link from 'next/link'
import { Button, IconButton } from '@fullfacing/schoolbus'

const LinkButton = ({
  children,
  isIcon,
  href = '/',
  linkProps,
  target,
  ...otherProps
}) => {
  const ButtonComponent = isIcon ? IconButton : Button

  return (
    <Link legacyBehavior href={href} {...linkProps}>
      <a target={target}>
        <ButtonComponent {...otherProps}>{children}</ButtonComponent>
      </a>
    </Link>
  )
}

export default LinkButton
