import { Container } from '@lib/utils/stateful'
import Stoor from 'stoor'

const LANGUAGE_NAMESPACE_KEY = 'language'
const ACTIVE_LANGUAGE_KEY = 'current-language'
const INITIAL_VALUES = { languages: [] }

class Languages extends Container {
  constructor () {
    super()

    this.languageStorage = new Stoor({ namespace: LANGUAGE_NAMESPACE_KEY })

    const { languages } = this.languageStorage.get(
      ACTIVE_LANGUAGE_KEY,
      INITIAL_VALUES
    ) || INITIAL_VALUES

    this.state = {
      languages
    }
  }

  set (languages = {}) {
    const newState = {
      ...this.state,
      languages
    }

    return this.setState(newState).then(() => {
      this.languageStorage.set(ACTIVE_LANGUAGE_KEY, newState)
    })
  }
}

const languages = new Languages()

export default languages
