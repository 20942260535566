import styled from 'styled-components'

const Select = styled.select`
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: ${({ theme }) => theme.fontSizes.small};
  appearance: none;
  background-position: right center;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: 0.9em;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAADmwAAA5sBPN8HMQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAJxSURBVGiB7da/a1NRGMbx72OgIEg3Q/8BB3V1cpB7QaJU8QcOdujm5iAGXVo6nNsmZAnNVhBndeiigwWhw42IQ4dSkFakUKhQEEpFoqGhTe51sMUSE72/YpfzGc9933Oe955wCViWZVmWZVmWZVmpqHvBGDMbhmHxOMLEMOt53qOjC7nuCt/33ziOcwq4+N9ixVP1PO9x9+IfgwDU6/VF13VHgAsDjxWDpCee5z3s9exEn54QuA88H1iqmCQ941emsNfznjcC4Pt+mM/nX+Xz+bPA+QHli0TS/Orq6vjc3FzQr6bfjQAwPz/fAcaBhazDRSXpNTB+kKV/XZTNisXiyeHh4QXAySBbHH6j0Rit1Wq7/yr8640cqtVqu5JuAkupo0X3rtlsXo8yBEQcBMAY05B0BVhOHC265Vardb1arTajNkT6aR01MTFxemhoyAfOxe2NaG1vb8+pVCrbcZoi38ihSqWy3W63C8BG3N4INtrtdiHuEJBgEIByubwlyZW0maS/F0mbktxyubyVqD/N4VNTU2dyudxbYCTNPsCXTqdzqVQqrSfdINGNHCqVSutBEBQk7STdQ9JOEASFNENAykEAZmZmPgCjkr4naP8GXD7YI5XUgwAYY5aAq5Iify6BH5KuGWNWssiQySAAxpj3wC2gFaG8Jen2QU8mMhsEwBizGIbhmKT9fjWS9iXdNcYsZnl233+/SdXr9U+O43yUdIeuFyWpEwTBmOd5L7M+N/NBAHzfX3Mc5zNwg9+f+CAMw3vT09MvBnHmQAYB8H1/xXXdr8AogKQHnuc9HdR5A2eMmTTGTB53DsuyLMuyrGPwE3/kv+hn6GlpAAAAAElFTkSuQmCC');
  padding: 0.6em;
  width: ${({ width }) => width || '4.4rem'};
  background-color: ${({ theme }) => theme.colors.transparent};
  border-color: ${({ theme }) => theme.colors.grayscale.xlight};
  ${({ gray, theme }) => gray && theme.colors.grayscale.dark};
  ${({ bold, theme }) => bold && theme.fontWeights.bold};
`

export default Select
