import styled from 'styled-components'

export default styled.button.attrs({
  type: 'button'
})`
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ theme }) => theme.fonts.Montserrat};
  width: ${({ block }) => (block ? '100%' : 'auto')};
  color: ${({ secondary, primary, theme }) => {
    if (secondary) return theme.colors.grayscale.default
    return primary ? theme.colors.white : theme.colors.black
  }};
  background-color: ${({ disabled, primary, theme }) => {
    if (disabled) return theme.colors.grayscale.default
    return primary ? theme.colors.primary.default : theme.colors.white
  }};
  padding: 10px 20px;
  border: none;
  border-radius: ${({ theme }) => theme.radii.small};
  box-shadow: ${({ raised, theme }) =>
    raised ? theme.shadows.titleBar : 'none'};
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'inherit')};
  min-width: 90px;
  transition: transform 0.1s;

  &:active {
    transform: scale(0.95);
  }
`
