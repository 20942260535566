import styled from 'styled-components'

const LimitReachedBanner = styled.p`
  text-align: center;
  margin: 1em 0;
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.primary.default};
  background-color: ${({ theme }) => theme.colors.grayscale.xlight};
  padding: 0.8em 1em;
  border-radius: ${({ theme }) => theme.radii.large};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

export default LimitReachedBanner
