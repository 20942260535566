import { Container } from '@lib/utils/stateful'
import { insertAtIndex } from '@fullfacing/ff-web-utils'

const initialValues = {
  uploadResults: []
}

class UploadResultsContainer extends Container {
  state = { ...initialValues }

  updateResult = (result, callback) => {
    const { uploadResults } = this.state
    if (!result?.key) return
    const originalResult = uploadResults.find(({ key }) => result?.key === key)

    this.setResult(
      { ...originalResult, ...result },
      callback
    )
  }

  setResult = (result, callback) => {
    if (!result) return
    const { uploadResults } = this.state
    const { key } = result
    if (!key) return
    const uploadIndex = uploadResults.findIndex(({ key: currentKey }) => key === currentKey)

    return this.set({
      uploadResults: uploadIndex === -1
        ? [...uploadResults, result]
        : insertAtIndex(uploadResults, uploadIndex, result)
    }, callback)
  }

  set (data = {}, callback) {
    const newState = {
      ...this.state,
      ...data
    }

    return this.setState(newState).then(() => {
      if (callback) callback()
    })
  }

  clear (callback) {
    this.set(initialValues, callback)
  }
}

const uploadResults = new UploadResultsContainer()

export default uploadResults
