import styled from 'styled-components'
import { Flex } from '@fullfacing/schoolbus'

export default styled(Flex).attrs({
  flexDirection: 'column',
  width: '75rem',
  p: '0 0 2em 0',
  m: '0 auto'
})`
  max-width: 90vw;
  min-height: 100%;
`
