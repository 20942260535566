const environments = [
  { name: 'develop', keys: ['dev'] },
  { name: 'staging', keys: ['staging'] },
  { name: 'uat', keys: ['uat'] },
  { name: 'prod', keys: ['prod', 'portal'] }
]

export default function getEnvironment () {
  const url = process.env.NEXT_PUBLIC_ENVIRONMENT

  if (!url) return
  const environment = environments.find(({ keys }) =>
    keys.some(key => url.includes(key))
  )
  return environment?.name || ''
}
