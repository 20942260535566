export const videoSpec = [
  {
    criteriaTitle: 'Resolution',
    criteria: '1080p'
  },
  {
    criteriaTitle: 'Format',
    criteria: 'MPEG-4 (.mp4)'
  },
  {
    criteriaTitle: 'Maximum file size',
    criteria: '3 GB'
  },
  {
    criteriaTitle: 'Codec',
    criteria: 'h.264 or x.264'
  }
]

export const imageSpec = [
  {
    criteriaTitle: 'Resolution',
    criteria: '800 x 600 pixels (minimum) to 1920 x 1080 pixels (maximum)'
  },
  {
    criteriaTitle: 'Aspect ratio',
    criteria: 'Landscape'
  },
  {
    criteriaTitle: 'Format',
    criteria: 'JPEG (.jpg, .jpeg) or PNG (.png)'
  }
]

export const subtitleSpec = [
  {
    criteriaTitle: 'Format',
    criteria: 'SRT (.srt)'
  }
]

export const audioSpec = [
  {
    criteriaTitle: 'Format',
    criteria: 'MP3 (.mp3) or WAV (.wav)'
  },
  {
    criteriaTitle: 'Bitrate',
    criteria: '96-320 Kbps'
  }
]

export const documentSpec = [
  {
    criteriaTitle: 'Format',
    criteria: 'PDF (.pdf)'
  },
  {
    criteriaTitle: 'Maximum file size',
    criteria: '20 MB'
  }
]

export const pdfSpec = [
  {
    criteriaTitle: 'Format',
    criteria: 'PDF (.pdf)'
  },
  {
    criteriaTitle: 'Maximum file size',
    criteria: '20 MB'
  }
]

export const svgSpec = [
  {
    criteriaTitle: 'Format',
    criteria: 'SVG (.svg)'
  }
]

export const signatureImageSpec = [
  {
    criteriaTitle: 'Aspect ratio',
    criteria: 'Landscape'
  },
  {
    criteriaTitle: 'Format',
    criteria: 'JPEG (.jpg, .jpeg) or PNG (.png)'
  }
]

const mediaSpecMap = {
  videoSpec,
  imageSpec,
  subtitleSpec,
  audioSpec,
  documentSpec,
  pdfSpec,
  svgSpec,
  signatureImageSpec
}

export default mediaSpecMap
