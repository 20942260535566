import { createGlobalStyle } from 'styled-components'

import { theme } from '@fullfacing/schoolbus'

const { colors, fonts } = theme

const GlobalStyles = createGlobalStyle`
  html, body {
    height: 100%;
    font-family: ${fonts.Montserrat};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *, *:before, *:after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    outline: transparent;
  }

  body {
    background-color: ${({ dark }) =>
      dark ? colors.grayscale.dark : colors.white};
  }

  main, #__next {
    height: 100%;
    scroll-behavior: smooth;
  }

  ul, li {
    list-style: none;
  }

  button {
    font-family: inherit;
    background-color: transparent;
    border: none;
  }

  button:not(:disabled) {
    cursor: pointer;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  .ReactModal__Overlay--after-open{
      opacity: 1;
  }

  .ReactModal__Overlay--before-close{
      opacity: 0;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.transparent};
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.grayscale.default};
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.grayscale.light};
  }

  @media (prefers-reduced-motion: reduce) {
    * {
      transition: none!important;
      animation-duration: 0!important;
    }
  }

  .video-js.vjs-big-play-centered > .vjs-big-play-button {
    transition: opacity 100ms ease-out;
    height: 1.8em;
    width: 1.8em;
    border-radius: ${({ theme }) => theme.radii.full};
    background-color: ${({ theme }) => theme.colors.black} !important;
    margin: 0;
    transform: translate(-50%, -50%);
    opacity: 0.4;
    &:hover {
      opacity: 0.3;
    }
  }

  .video-js.vjs-big-play-centered > .vjs-big-play-button .vjs-icon-placeholder:before {
    content: "";
    background-image: url('https://cdn.fullfacing.io/images/d79653dc-23da-47d1-9f39-a10b220ab410.svg');
    background-repeat: no-repeat;
    background-size: 35%;
    background-position: 55% 50%;
  }
`

export default GlobalStyles
