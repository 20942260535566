import userContainer from '@lib/containers/user'
import Router from 'next/router'
import { PAGE_PERMISSION_MAP } from '@lib/config/constants'

function redirectToAllowedTab () {
  const { permissions } = userContainer.state
  const { scopes } = permissions

  const routeIndex = Object.entries(PAGE_PERMISSION_MAP).findIndex(
    permissionMap => scopes.some(scope => scope.includes(permissionMap[1]))
  )
  if (routeIndex === -1) throw Error('No page permissions available.')
  const route = Object.keys(PAGE_PERMISSION_MAP)[routeIndex]

  return Router.replace(route)
}

export default redirectToAllowedTab
