import React from 'react'
import styled from 'styled-components'

const ToggleContainer = styled.div`
  display: block;
  padding-bottom: 3px;
  width: 60px;
  height: 40px;
  position: relative;
`

const ToggleInput = styled.input`
  display: none;
`

const ToggleLabel = styled.label`
  cursor: pointer;
  position: relative;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.grayscale.light};

  ::before {
    background: ${({ theme }) => theme.colors.grayscale.xlight};
    border-radius: 8px;
    content: '';
    height: 15px;
    margin-top: 15px;
    position: absolute;
    opacity: 0.3;
    width: 40px;
  }

  ::after {
    background: ${({ theme }) => theme.colors.grayscale.light};
    border-radius: 16px;
    box-shadow: ${({ theme }) => theme.shadows.titleBar};
    content: '';
    height: 24px;
    left: 0px;
    margin-top: 13px;
    position: absolute;
    top: -3px;
    width: 24px;
  }

  /* stylelint-disable-next-line selector-type-no-unknown */
  ${ToggleInput}:checked + &::before {
    background: ${({ theme }) => theme.colors.primary.default};
    opacity: 0.5;
  }

  /* stylelint-disable-next-line selector-type-no-unknown */
  ${ToggleInput}:checked + &::after {
    background: ${({ theme }) => theme.colors.primary.default};
    left: 20px;
  }
`

const Toggle = props => (
  <ToggleContainer>
    <ToggleInput type='checkbox' {...props} />
    <ToggleLabel htmlFor={props.id} />
  </ToggleContainer>
)

Toggle.defaultProps = {
  value: false
}

export default Toggle
