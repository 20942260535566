import { Container } from '@lib/utils/stateful'
import Stoor from 'stoor'

const ACCOUNT_NAMESPACE_KEY = 'account'
const ACTIVE_ACCOUNT_KEY = 'current-account'

const INITIAL_VALUES = {
  id: '',
  name: '',
  active: '',
  licenses: {}
}

class Accounts extends Container {
  constructor () {
    super()

    this.accountStorage = new Stoor({
      namespace: ACCOUNT_NAMESPACE_KEY
    })

    const { id, name, active, licenses } = this.accountStorage.get(
      ACTIVE_ACCOUNT_KEY,
      INITIAL_VALUES
    ) || INITIAL_VALUES
    //TODO: determine why the stoor fallback is not used

    this.state = {
      id,
      active,
      name,
      licenses
    }
  }

  async clear () {
    await this.accountStorage.set(ACTIVE_ACCOUNT_KEY, { ...INITIAL_VALUES })
    return this.setState({ ...INITIAL_VALUES })
  }

  set (data = {}) {
    const newState = {
      ...INITIAL_VALUES,
      ...data
    }

    return this.setState(newState).then(() => {
      this.accountStorage.set(ACTIVE_ACCOUNT_KEY, newState)
    })
  }
}

const account = new Accounts()

export default account
