import styled from 'styled-components'

const Label = styled.label`
  display: block;
  font-family: inherit;
  color: ${({ theme }) => theme.colors.grayscale.dark};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  margin-bottom: 0.4em;
`

export default Label
