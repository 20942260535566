import useSWR from 'swr'

import userContainer from '@lib/containers/user'
import api from '@api'

const fetchCount = async ({ userId, viewed, type }) =>
  await api.messaging.count(userId, {
    params: { viewed, type }
  })

const useMessageCount = (config = {}) => {
  const { viewed } = config
  const { data: count = 0, mutate } = useSWR(
      () => ({
        userId: userContainer.state.id,
        type: 'HeadOffice',
        ...typeof viewed === 'boolean' && {
          viewed
        }
      }),
      fetchCount,
      { onError: console.error }
    )

  return {
    count,
    refreshCount: mutate
  }
}

export default useMessageCount
