import styled from 'styled-components'
import AlertBox from './alert-box'

const EmptyStateAlert = styled(AlertBox).attrs(props => ({
  m: props.m || '1em 0',
  padding: '1em 2em',
  color: 'primary.default',
  width: 'auto',
  bg: 'grayscale.xxlight'
}))`
  border: none;
  font-weight: ${({ theme }) => theme.fontWeights.semi};
  text-align: center;
`

export default EmptyStateAlert
