import styled from 'styled-components'

export const ModalDescription = styled.p`
  font-size: 13px;
  margin-bottom: 2em;
  color: #7c7e7f;
  font-family: ${({ theme }) => theme.fonts.Montserrat};
`

export default ModalDescription
