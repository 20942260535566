import styled from 'styled-components'
import { Flex } from '@fullfacing/schoolbus'

const FormActions = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'flex-end',
  m: '2em 0 1em'
})``

export default FormActions
