import { useState, useEffect } from 'react'

const useContainerContext = (container) => {
  const [state, setState] = useState(container.state)
  const handleStateChange = () => setState(container.state)

  useEffect(() => {
    container.subscribe(handleStateChange)
    return () => container.unsubscribe(handleStateChange)
  }, [])

  return state
}

export default useContainerContext
