import React from 'react'

// Page loader styling needs to be included in _app.js
// We use CSS instead of styled-components to prevent the flash of unstyled content

const PageLoader = () => (
  <div className='page-loader-container' role='alert' aria-live='polite'>
    <img
      className='page-loader-back'
      src='https://cdn.fullfacing.io/images/7cd68980-1b2a-4285-a0f5-3cf78bfdbd56.png'
      alt='Loading'
    />
    <img
      className='page-loader-front'
      src='https://cdn.fullfacing.io/images/830215e4-0e99-4afa-99d4-2a78fd924aae.png'
      alt=''
    />
  </div>
)

export default React.memo(PageLoader)
